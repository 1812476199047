import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';//BrowserRouter, 
import { LicenseManager } from "@ag-grid-enterprise/core";
import App from './App';
import { unregister }  from './registerServiceWorker.js';

LicenseManager.setLicenseKey("CompanyName=Clear Demand Inc.,LicensedApplication=Nathan Sills,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-010160,ExpiryDate=2_September_2021_[v2]_MTYzMDUzNzIwMDAwMA==1d4feaa7302ba4efc63cd0ccfa97da65");
// LicenseManager.setLicenseKey("CompanyName=Clear Demand Inc.,LicensedApplication=Nathan Sills,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-017667,ExpiryDate=2_September2022[v2]_MTY2MjA3MzIwMDAwMA==6476aef30672ad4cfd2f865f4ebc97305");

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    
    <HashRouter basename={baseUrl as string}>
        
        <div id="App"></div>
        <App />
    </HashRouter>,
    rootElement);

unregister();    
//registerServiceWorker();

