//core imports
import * as React from 'react';

import MasterContext from "./Components/React/Misc/MasterContext"
import { Route } from 'react-router-dom';
import { Layout } from './Components/React/Layout';
import IdleTimer from 'react-idle-timer';
import { ActiveOrigin } from './Components/React/ActiveOrigin';

//our components
import LOVs from './Components/React/LOVs/LOVs'
import { Home } from './Components/React/Home';
import Register from './Components/React/ASPNETForms/Register/Register';
import Roles from "./Components/React/Roles/Roles";

import RulesExplorer from './Components/React/RulesExplorer/RulesExplorer';
import ExecutiveSummary from './Components/iFramed/ExecutiveSummary/ExecutiveSummary';

import StateMinimum from './Components/React/StateMinimum/StateMinimum';
import PriceReviewFramed from './Components/iFramed/PriceReviewFramed/PriceReviewFramed';

import ManageRules from './Components/iFramed/ManageRules/ManageRules'

import SearchProductGroup from './Components/React/ProductGroups/SearchProductGroup';
import CreateProductGroup from './Components/React/ProductGroups/CreateProductGroup';
import BuydownGrid from './Components/React/Buydown/BuydownGrid';

import ManageProductGroupsSearch from './Components/iFramed/ManageProductGroupsSearch/ManageProductGroupsSearch';
import ManageProductGroupsCreate from './Components/iFramed/ManageProductGroupsCreate/ManageProductGroupsCreate';
import ManageProductGroupsImport from './Components/iFramed/ManageProductGroupsImport/ManageProductGroupsImport';
import ManageProductGroupsExport from './Components/iFramed/ManageProductGroupsExport/ManageProductGroupsExport';

import ManageLocationGroupsSearch from './Components/iFramed/ManageLocationGroupsSearch/ManageLocationGroupsSearch';
import ManageLocationGroupsCreate from './Components/iFramed/ManageLocationGroupsCreate/ManageLocationGroupsCreate';
import ManageLocationGroupsImport from './Components/iFramed/ManageLocationGroupsImport/ManageLocationGroupsImport';
import ManageLocationGroupsExport from './Components/iFramed/ManageLocationGroupsExport/ManageLocationGroupsExport';

import ManageScenario from './Components/iFramed/ManageScenariosManage/ManageScenario';
import ManageScenariosManage from './Components/iFramed/ManageScenariosManage/ManageScenariosManage';
import ManageScenariosCreate from './Components/iFramed/ManageScenariosCreate/ManageScenariosCreate';

import ManageDimensions from './Components/iFramed/ManageDimensions/ManageDimensions';

import PromotionEvent from './Components/iFramed/Promotions/PromotionsEvents';
import PromotionsEventsManage from './Components/iFramed/Promotions/PromotionsEventsManage';
import PromotionsEventsCreate from './Components/iFramed/Promotions/PromotionsEventsCreate';

import PromotionsTemplatesManage from './Components/iFramed/Promotions/PromotionsTemplatesManage';

import PromotionsAdSetsManage from './Components/iFramed/Promotions/PromotionsAdSetsManage';
import PromotionsAdSetsCreate from './Components/iFramed/Promotions/PromotionsAdSetsCreate';

import EngineRun from './Components/iFramed/EngineRun/EngineRun';
import EngineJobStatus from './Components/iFramed/EngineJobStatus/EngineJobStatus';
import EngineTaskStatus from './Components/iFramed/EngineTaskStatus/EngineTaskStatus';
import TableauHost from './Components/iFramed/TableauHost/TableauHost';

import ManageGrid from './Components/iFramed/AdminSettingsManageGrids/ManageGrid';
import AdminSettingsManageGrids from './Components/iFramed/AdminSettingsManageGrids/AdminSettingsmanageGrids';
import LockDays from './Components/React/LockDays/LockDays';
import AdminPriceChangeDays from './Components/React/Admin/PriceChangeDays';
import AdminFutureBuydownDays from './Components/React/Admin/FutureBuydownDays';
import TopFilterOptions from './Components/iFramed/TopFilterOptions/TopFilterOptions';
import Users from "./Components/React/Users/Users";
import AdminManageNodeServers from './Components/iFramed/AdminManageNodeServers/AdminManageNodeServers';
import AdminAuditHistory from './Components/iFramed/AdminAuditHistory/AdminAuditHistory';

import TableauEditor from './Components/React/Tableau/TableauEditor';

import PermissionWarning from './Components/React/PermissionWarning/PermissionWarning';
import FileManager from './Components/iFramed/FileManager/fileManager';
import HelpLegacy from './Components/iFramed/HelpLegacy/HelpLegacy'
import UserProfile from './Components/React/UserProfile/UserProfile';
import ChangePassword from './Components/React/ChangePassword/ChangePassword';
import LogOut from './Components/React/LogOut/LogOut';

import CDIExplorer from './Components/React/Explorer/Explorer'
import AdminAudit from './Components/React/AdminAudit/AdminAudit';
import Settings from './Components/React/Settings/Settings';
import MessageManager from './Components/React/MessageManager/MessageManager'

import RunManager from './Components/React/JobControl/RunManager'
import JobStatusGrid from './Components/React/JobControl/JobStatusGrid';
import TaskStatusGrid from './Components/React/JobControl/TaskStatusGrid';
import ManageDepartmentNotifications from './Components/React/AuditAction/ManageDepartmentNotifications';

/*import { useBeforeunload } from 'react-beforeunload';*/

export interface IProps {
}

export interface IState {
    loaded: boolean;
    activeRoutes: string[];
    logoutOnTabBrowserClose: boolean;
    checkingSso: boolean;
}

class App extends React.Component<IProps, IState> {
    static displayName = App.name;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loaded: false,
            activeRoutes: [],
            logoutOnTabBrowserClose: false,
            checkingSso: localStorage.getItem("cachedUser") === null
        }

        if (localStorage.getItem("cachedUser") && localStorage.getItem("navigationPermissions")) {
            MasterContext.UserData = {
                userName: localStorage.getItem("cachedUser"),
                navigationPermissions: JSON.parse(localStorage.getItem("navigationPermissions"))
            };
        }
        else {
            MasterContext.UserData = {
                userName: '',
                navigationPermissions: []
            };
        }

        if (localStorage.getItem("clientTimeoutValue")) {
            MasterContext.ClientTimeoutValue = +localStorage.getItem("clientTimeoutValue");
        }

        this.refreshLogin = this.refreshLogin.bind(this);
        this.idleTimer = null;
        this.onAction = this.onAction.bind(this);
        this.onActive = this.onActive.bind(this);
        this.onIdle = this.onIdle.bind(this);
    }

    componentDidMount() {
        MasterContext.ModuleFeatureService.listModules((res) => {
            MasterContext.AllModules = res;
            localStorage.setItem('modules', JSON.stringify(res));

            // Set client option whether to be logged out on browser close
            const logoutIsActive = res?.[0]?.Features.find(f => f.FeatureName === 'LogoutOnTabBrowserClose')?.IsActive;
            this.setState({ logoutOnTabBrowserClose: logoutIsActive || false });
            
        }, (err) => console.log(err));
    }

    idleTimer: IdleTimer;

    onAction(e) {
       // console.log('user did something', e)
    }

    onActive(e) {
       // console.log('user is active', e)
       // console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    onIdle(e) {
       // console.log('user is idle', e)
       // console.log('last active', this.idleTimer.getLastActiveTime())
        localStorage.removeItem("cachedUser");
        localStorage.removeItem("navigationPermissions");
        localStorage.removeItem("clientTimeoutValue");
        localStorage.setItem("FLO","1");

        MasterContext.UserData = {
            userName: '',
            navigationPermissions: []
        };
        this.forceUpdate();
        
    }

    refreshLogin() {
        this.forceUpdate();
    }

    public doSomething = () => {
        alert('blah');
    }
    
    public toggle = () => {
        this.setState({ loaded: true });
    }
    
    render() {
        return (
            <React.Fragment>
                { this.state.logoutOnTabBrowserClose &&
                    <ActiveOrigin />
                }

                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={MasterContext.ClientTimeoutValue} />

                <Layout refreshLogin={this.refreshLogin} checkingSso={this.state.checkingSso}>
                    <Route exact path='/' 
                        render={(props) => <Home />} />
                    <Route exact path='/login' 
                        render={(props) => <Home />} />
                    <Route path='/register' render={(props: any) => <Register />} />
                    <Route path='/managerules' 
                        render={MasterContext.hasNavigationPermission('Manage > Rules') 
                        ? (props: any) => <ManageRules /> 
                        : (props: any) => <PermissionWarning /> } />
                    <Route path='/manageproductgroupssearch' 
                        render={MasterContext.hasNavigationPermission('Manage > Product Groups > Search') 
                        ? (props: any) => <ManageProductGroupsSearch /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/manageproductgroupssearchlegacy' 
                        render={MasterContext.hasNavigationPermission('Manage > Product Groups > Search') 
                        ? (props: any) => <SearchProductGroup /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/manageproductgroupscreate' 
                        render={MasterContext.hasNavigationPermission('Manage > Product Groups > Create') 
                        ? (props: any) => < ManageProductGroupsCreate/> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/manageproductgroupscreatelegacy' 
                        render={MasterContext.hasNavigationPermission('Manage > Product Groups > Create') 
                        ? (props: any) => <CreateProductGroup /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/manageproductgroupsimport' 
                        render={MasterContext.hasNavigationPermission('Manage > Product Groups > Import Groups') 
                        ? (props: any) => <ManageProductGroupsImport /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/manageproductgroupsexport' 
                        render={MasterContext.hasNavigationPermission('Manage > Product Groups > Export Groups') 
                        ? (props: any) => <ManageProductGroupsExport /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managelocationgroupssearch' 
                        render={MasterContext.hasNavigationPermission('Manage > Location Groups > Search') 
                        ? (props: any) => <ManageLocationGroupsSearch /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managelocationgroupscreate' 
                        render={MasterContext.hasNavigationPermission('Manage > Location Groups > Create') 
                        ? (props: any) => <ManageLocationGroupsCreate /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managelocationgroupsimport' 
                        render={MasterContext.hasNavigationPermission('Manage > Location Groups > Import Groups') 
                        ? (props: any) => <ManageLocationGroupsImport /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managelocationgroupsexport' 
                        render={MasterContext.hasNavigationPermission('Manage > Location Groups > Export Groups') 
                        ? (props: any) => <ManageLocationGroupsExport /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managescenariosmanage' 
                        render={MasterContext.hasNavigationPermission('Manage > Scenarios > Manage') 
                        ? (props: any) => <ManageScenariosManage /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/scenarios' 
                        render={MasterContext.hasNavigationParentPermission('Manage > Scenarios') 
                        ? (props: any) => <ManageScenario /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managescenarioscreate' 
                        render={MasterContext.hasNavigationPermission('Manage > Scenarios > Create') 
                        ? (props: any) => <ManageScenariosCreate /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managedimensions' 
                        render={MasterContext.hasNavigationPermission('Manage > Dimensions') 
                        ? (props: any) => <ManageDimensions /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/pricereview' 
                        render={MasterContext.hasNavigationPermission('Regular > Price Review') 
                        && MasterContext.QueryModule('regular')
                        ? (props: any) => <PriceReviewFramed {...props} /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/executivesummary' 
                        render={MasterContext.hasNavigationPermission('Regular > Executive Summary') 
                        && MasterContext.QueryModule('regular')
                        ? (props: any) => <ExecutiveSummary /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/rulesexplorer' 
                        render={MasterContext.hasNavigationPermission('Regular > Rules Explorer') 
                        && MasterContext.QueryModule('regular')
                        && MasterContext.QueryModuleFeature('regular', 'rulesexplorer')
                        ? (props: any) => <RulesExplorer /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/promotion' 
                        render={MasterContext.hasNavigationParentPermission('Promotions') 
                        && MasterContext.QueryModule('promotion')
                        ? (props: any) => <PromotionEvent /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/promotionseventsmanage' 
                        render={MasterContext.hasNavigationPermission('Promotions > Events > Manage') 
                        && MasterContext.QueryModule('promotion')
                        ? (props: any) => <PromotionsEventsManage /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/promotionseventcreate' 
                        render={MasterContext.hasNavigationPermission('Promotions > Events > Create') 
                        && MasterContext.QueryModule('promotion')
                        ? (props: any) => <PromotionsEventsCreate /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/promotionstemplatesmanage' 
                        render={MasterContext.hasNavigationPermission('Promotions > Templates > Manage') 
                        && MasterContext.QueryModule('promotion')
                        ? (props: any) => <PromotionsTemplatesManage /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/promotionsadsetsmanage' 
                        render={MasterContext.hasNavigationPermission('Promotions > Ad Sets > Manage') 
                        && MasterContext.QueryModule('promotion')
                        ? (props: any) => <PromotionsAdSetsManage /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/promotionsadset' 
                        render={MasterContext.hasNavigationParentPermission('Promotions > Ad Sets') 
                        && MasterContext.QueryModule('promotion')
                        ? (props: any) => <PromotionsAdSetsManage /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/promotionsadsetcreate' 
                        render={MasterContext.hasNavigationPermission('Promotions > Ad Sets > Create') 
                        && MasterContext.QueryModule('promotion')
                        ? (props: any) => <PromotionsAdSetsCreate /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/enginerun' 
                        render={MasterContext.hasNavigationPermission('Engine > Run') 
                        ? (props: any) => <EngineRun /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/enginejobstatus' 
                        render={MasterContext.hasNavigationPermission('Engine > Job Status') 
                        ? (props: any) => <EngineJobStatus /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/enginetaskstatus' 
                        render={MasterContext.hasNavigationPermission('Engine > Task Status') 
                        ? (props: any) => <EngineTaskStatus /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/tableauhost' render={(props: any) => <TableauHost />} /> {/* // needs nav permission */}
                    <Route path='/adminsettingsmanagegrids' 
                        render={MasterContext.hasNavigationPermission('Admin > Settings > Manage Grids') 
                        ? (props: any) => <AdminSettingsManageGrids /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managegrid' 
                        render={MasterContext.hasNavigationPermission('Admin > Settings > Manage Grids') 
                        ? (props: any) => <ManageGrid /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/adminsettingslockdays' 
                        render={MasterContext.hasNavigationPermission('Admin > Settings > Lock Days') 
                        ? (props: any) => <LockDays /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/adminpricechangedays' 
                        render={MasterContext.hasNavigationPermission('Admin > Settings > Price Change Days') 
                        ? (props: any) => <AdminPriceChangeDays /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/adminfuturebuydowndays' 
                        render={MasterContext.hasNavigationPermission('Admin > Settings > Future Buydown Days') 
                        ? (props: any) => <AdminFutureBuydownDays /> 
                        : (props: any) => <PermissionWarning />} />

                    <Route path='/topfilteroptions' 
                        render={MasterContext.hasNavigationPermission('Admin > Settings > Future Buydown Days') 
                        ? (props: any) => <TopFilterOptions /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/users' 
                        render={MasterContext.hasNavigationPermission('Admin > Users & Roles > Manage Users') 
                        ? (props: any) => <Users /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/roles' 
                        render={MasterContext.hasNavigationPermission('Admin > Users & Roles > Manage Roles') 
                        ? (props: any) => <Roles /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/adminmanagereportsmenu' 
                        render={MasterContext.hasNavigationPermission('Admin > Manage Reports Menu') 
                        ? (props: any) => <TableauEditor /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/adminmanagenodeservers' 
                        render={MasterContext.hasNavigationPermission('Admin > Manage Node Servers') 
                        ? (props: any) => <AdminManageNodeServers /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/managedepartmentnotifications' 
                        render={MasterContext.hasNavigationPermission('Admin > Manage Department Notifications') 
                        ? (props: any) => <ManageDepartmentNotifications /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/adminaudithistory' 
                        render={MasterContext.hasNavigationPermission('Admin > Audits > Audit History') 
                        ? (props: any) => <AdminAuditHistory /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/adminaudit' 
                        render={MasterContext.hasNavigationPermission('Admin > Audits > User History') 
                        ? (props: any) => <AdminAudit /> 
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/filemanager' render={(props: any) => <FileManager />} />
                    <Route path='/help' render={(props: any) => <HelpLegacy />} />
                    <Route path='/userprofile' render={(props: any) => <UserProfile />} />
                    <Route path='/changepassword' render={(props: any) => <ChangePassword />} />
                    <Route path='/logout' render={(props: any) => <LogOut handleLogout={this.refreshLogin} />} />
                    <Route path='/insecureroutetest' render={(props: any) => <Home />} />
                    <Route path='/stateminimum'
                        render={MasterContext.hasNavigationPermission('Regular > State Minimum Editor')
                        ? (props: any) => <StateMinimum />
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/buydowns' 
                        render={MasterContext.hasNavigationPermission('Regular > Buydown Explorer')
                        && MasterContext.QueryModuleFeature('regular', 'buydowns')
                        ? (props: any) => <BuydownGrid />
                        : (props: any) => <PermissionWarning />} />
                    <Route path='/explorer' render={(props: any) => <CDIExplorer />} />
                    <Route path='/explorerdownload' render={(props: any) => <FileManager />} />
                    <Route path='/lovs' render={(props: any) => <LOVs />} />
                    <Route path='/settings' render={(props: any) => <Settings />} />
                    <Route path='/messagemanager' render={(props: any) => <MessageManager />} />
                    <Route path='/runmanager' render={(props: any) => <RunManager />} />
                    <Route path='/jobstatus' render={(props: any) => <JobStatusGrid />} />
                    <Route path='/taskstatus' render={(props: any) => <TaskStatusGrid />} />
                </Layout>
            </React.Fragment>
        );
    }
}
export default App;