import * as React from 'react';
import Spinner from '../../React/Misc/Spinner';
import MasterContext from '../../React/Misc/MasterContext';
import { urlConfig } from "../../../Services/Misc/dataLayer.config";
export interface IProps {
}
export interface IState {
    loaded: boolean;
}

export default class FileManager extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { loaded: false }
        this.componentDidMount = this.componentDidMount.bind(this);

    }
    componentDidMount() {
        window.onmessage = (e) => {
            if (e.data === 'childDidLoad') {
                this.setState({ loaded: false });
            }
        }
    }

    componentWillUnmount() {
        window.onmessage = (e) => {

        }
    }
    getURL(): string {
        let retVal = '';
        var token = localStorage.getItem("userToken");

        let queryString = window.top.location.hash.split('?').pop();
        console.log("File Manager qs : " + queryString);
        if (queryString.includes('action=')) {
            let url = MasterContext.REACTURL + "/PricingEngineService/explorer/download/?apiKey=" + token + "&" + queryString;
            console.log("window location url : " + url);
            //window.location.href = url;

            // Add a spinner to the page while the file is being downloaded.
            document.body.innerHTML += '<div id="downloadMessage" class="downloading-message"><label>Downloading...</label> </div>';

            fetch(url, {
                headers: {'Content-Type': 'blob', 'Content-Disposition': 'attachment'}
            }).then(
                    (result) => {
                        console.log(result);
                        result.blob().then((data) => {
                            var fnameArr = result.url.split('%2F');
                            var fname = fnameArr[fnameArr.length - 1];
                            const url = window.URL.createObjectURL(data);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = fname;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);

                            // Remove the message after the file has been downloaded.
                            document.getElementById('downloadMessage').style.display = "none";

                            // Redirect the user back to the file manager.
                            window.top.location.href = MasterContext.REACTURL + "/#/filemanager";
                            //alert('your file has downloaded!'); // or you know, something with better UX...

                            /*var reader = new FileReader();
                            reader.readAsDataURL(data);
                            reader.onloadend = function () {
                                var base64data = reader.result;
                                var fnameArr = result.url.split('%2F');
                                var fname = fnameArr[fnameArr.length - 1];
                                console.log(base64data);
                                var a = document.createElement("a");
                                a.href = "data:Content-Type:application/octet-stream;Content-Disposition:base64," + base64data; 
                                a.target = '_blank';
                                a.download = fname; 
                                a.click(); //Downloaded file
                                window.top.location.href = MasterContext.REACTURL + "/#/filemanager";
                            }*/
                        })
                    }
                )
        }
        else {
            if (token != null) {
                retVal = MasterContext.LEGACYURL + "indexEXPL.html?apiKey=" + token + "#/explorer";
            } else {
                retVal = MasterContext.LEGACYURL + "indexEXPL.html#/explorer";
            }
        }
        return retVal;
    }



    render() {
        let style1 = { height: "405px" }
        let style2 = { border: "0px", height: "97vh" };
        return (
            <div id="mainBody" className="main-body container-fill ng-scope body-fill" event-control="" cdi-body-fill-container="" ui-view="" role="main" style={style1}>

                <Spinner
                    spinnerActive={this.state.loaded}
                    heightSet="99vh"
                    paddingSet="10%"
                    spinnerText={MasterContext.translate("spinner_loading_explorer")}  
                />
                <iframe title='iframeFileManager' id="iframeFileManager" src={this.getURL()} width="100%" style={style2} ></iframe>
            </div>
        );

    }

}