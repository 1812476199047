export const urlConfig = {
  //apiBase: "http://localhost:44363/"
    debugResponses: true,
    debugRequests: true,
  apiBase:
    window.location.port === "80" ||
    window.location.port === "443" ||
    window.location.port === ""
      ? window.location.protocol + "//" + window.location.hostname 
          : window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
};
